<template lang="">
  <footer class="flex justify-center border-t p-3">
    <div class="flex justify-between max-w-7xl w-full">
      <div class="sm:flex items-center justify-start">
          <a class="flex title-font font-medium items-center md:justify-start text-white">
            <img src="@/assets/logo.png" class="object-contain h-6" alt="">
          </a>
        <p class="ml-1 text-gray-400 text-sm text-center sm:text-left">© 2024 Alkaid Technologies</p>
      </div>
      <div class="sm:flex h-10 items-center">
        <span class="mr-3 text-gray-400">
          hello@ledgerflow.cloud
        </span>
        <a href="https://www.facebook.com/getalkaid" class="text-gray-400">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="text-gray-400 w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a href="https://twitter.com/getalkaid" class="ml-3 text-gray-400">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a href="https://instagram.com/getalkaid" class="ml-3 text-gray-400">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          class="w-5 h-5"
          viewBox="0 0 50 50"
          fill="currentColor"><path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path></svg>
        </a>
      </div>
      <div class="sm:flex items-center">
        <a class="block text-gray-400 hover:text-gray-500 sm:mr-2" href="https://ledgerflow.cloud/terms">Terms</a>
        <a class="block text-gray-400 hover:text-gray-500 sm:mr-2" href="https://ledgerflow.cloud/privacy">Privacy</a>
        <a class="block text-gray-400 hover:text-gray-500" href="https://ledgerflow.cloud/support">Support</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  
}
</script>
<style lang="">
    
</style>